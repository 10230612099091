import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { AppBar, Toolbar, Typography, TypographyProps } from "@mui/material";
import { Link } from "../links";
import { shouldForwardProp } from "../utils";

export const StyledAppBar = styled(AppBar, {
  shouldForwardProp,
})<PpTransparentBg>`
  ${({ $transparentBg, theme }): SerializedStyles => css`
    transition: ${theme.transitions.create(["background-color"])};
    ${!$transparentBg && `box-shadow: ${theme.shadows[1]}`};
    position: sticky;
    top: 0;
  `}
`;

type PpProgressBar = {
  $progressBar: boolean;
};

export const StyledToolbar = styled(Toolbar, { shouldForwardProp })<
  PpTransparentBg & PpProgressBar
>`
  ${({ $progressBar, $transparentBg, theme }): SerializedStyles => css`
    ${theme.cssMixins.appHeaderHeight};
    ${$progressBar &&
    css`
      margin-top: ${theme.cssMixins.progressBarHeight};
    `}
    display: flex;
    justify-content: space-between;
    transition: ${theme.transitions.create(["background-color"])};
    background: ${$transparentBg
      ? "transparent"
      : theme.palette.background.paper};
  `}
`;

type PpLogoLink = {
  $isLogoOverlapping?: boolean;
  $progressBar?: boolean;
};

export const LogoLink = styled(Link, { shouldForwardProp })<PpLogoLink>`
  ${({ $isLogoOverlapping, $progressBar, theme }): SerializedStyles => css`
    display: inline-flex;
    ${$isLogoOverlapping &&
    css`
      align-self: flex-start;
    `}
    ${$isLogoOverlapping &&
    $progressBar &&
    css`
      margin-top: -${theme.cssMixins.progressBarHeight};
    `}
    ${theme.breakpoints.down("sm")} {
      margin-left: ${theme.spacing(2)};
    }
    > img {
      cursor: pointer;
      max-height: ${$isLogoOverlapping ? "120px" : "32px"};
      max-width: 130px;
      ${theme.breakpoints.up("sm")} {
        max-height: ${$isLogoOverlapping ? "120px" : "42px"};
        max-width: 200px;
      }
    }
  `}
`;

export const NavItemsListMobile = styled("ul")`
  ${({ theme }): SerializedStyles => css`
    padding: ${theme.spacing(4, 5)};
    ${theme.cssMixins.colStretch};
    margin: 0;
  `}
`;

export const NavItemsListDesktop = styled("ul")`
  ${({ theme }): SerializedStyles => css`
    padding: ${theme.spacing(0, 0, 0, 4)};
    margin: 0;
    height: 100%;
    ${theme.cssMixins.rowVCentered};
    > li {
      margin: ${theme.spacing(0, 2.5)};
    }
    ${theme.breakpoints.down("sm")} {
      display: none;
    }
  `}
`;

function NavBaseItem(props: TypographyProps<"li">): React.ReactElement {
  return <Typography component="li" variant="navItem" {...props} />;
}

export const NavItem = styled(NavBaseItem, {
  shouldForwardProp,
})<PpTransparentBg>`
  ${({ $transparentBg, theme }): SerializedStyles => css`
    list-style-type: none;
    > a {
      transition: ${theme.transitions.create("color")};
      color: ${$transparentBg
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main};
      ${theme.breakpoints.down("sm")} {
        color: ${theme.palette.primary.main};
      }
      ${theme.breakpoints.up("sm")} {
        text-shadow: ${$transparentBg ? theme.shadows[4] : "none"};
        &:hover {
          color: ${theme.palette.site.header.activeLink};
        }
      }
      &:hover {
        opacity: 1;
      }
    }
    ${theme.breakpoints.up("sm")} {
      height: 100%;
      ${theme.cssMixins.rowVCentered};
      /* class applied only for active menu item (matching current url), only on desktop */
      .active-link {
        color: ${theme.palette.site.header.activeLink};
      }
    }
    ${theme.breakpoints.down("sm")} {
      margin: ${theme.spacing(1, 0)};
    }
  `}
`;

export const CTALink = styled(Link)`
  ${({ theme }): SerializedStyles => css`
    display: inline-flex;
    margin-left: ${theme.spacing(2.5)};
    ${theme.breakpoints.down("sm")} {
      > button {
        padding: 6px 15px !important;
      }
    }
  `}
`;

export const HeaderMobile = styled("div")`
  ${({ theme }): SerializedStyles => css`
    width: 100%;
    height: 100%;
    ${theme.cssMixins.rowVCentered};
    justify-content: space-between;
    ${theme.breakpoints.up("sm")} {
      display: none;
    }
  `}
`;

export const HeaderMobileLeft = styled("div")`
  ${({ theme }): SerializedStyles => css`
    height: 100%;
    ${theme.cssMixins.rowVCentered};
  `}
`;

export const HeaderDesktop = styled("div")`
  ${({ theme }): SerializedStyles => css`
    height: 100%;
    width: 100%;
    ${theme.cssMixins.rowVCentered};
    justify-content: space-between;
    ${theme.breakpoints.down("sm")} {
      display: none;
    }
  `}
`;

export const MobileMenu = styled("div")`
  ${({ theme }): SerializedStyles => css`
    position: fixed;
    z-index: 10000;
    ${theme.cssMixins.stretchTop};
    height: 100vh;
    width: 100vw;
    background: ${theme.palette.background.paper};
  `}
`;

export const MenuContainer = styled("div")`
  height: 100%;
`;

export const MenuHeader = styled("div", { shouldForwardProp })<PpProgressBar>`
  ${({ $progressBar, theme }): SerializedStyles => css`
    width: 100%;
    ${theme.cssMixins.rowVCentered};
    justify-content: space-between;
    padding: ${theme.spacing(0, 2)};
    ${theme.cssMixins.appHeaderHeight};
    ${$progressBar &&
    css`
      margin-top: ${theme.cssMixins.progressBarHeight};
    `}
  `};
`;
